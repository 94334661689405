import Modal from "components/organisms/Modal";
import Faq from "pages/Faq";
import PatientAppointments from "pages/PatientAppointments";
import UserProfile from "pages/UserProfile";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import MobileHeader from "../../components/molecules/MobileHeader";
import ChatRoom from "../../components/organisms/ChatRoom";
import DocSheet from "../../components/organisms/DocSheet";
import { useAppDispatch } from "../../customHooks/reduxHooks";
import { setInfo } from "../../store/slices/infoSlice";
import { getToken } from "../../utils/getToken";
import { trpc } from "../../utils/trpc";
import styles from "./Chat.module.scss";
import routes from "routes";
import Button from "components/atoms/Button";
import FormHeader from "components/atoms/FormHeader";
import { analyticsService } from "../../utils/analyticsService";
import Emitter from "../../utils/eventemitter";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { raiseException } from "../../utils/raiseException";
import MedicalDocuments from "../../components/organisms/MedicalDocuments";
import { Profile } from '../Profile/Profile';
import { useTherapistData } from '../../customHooks/therapistDataContext';
import Support from '../../components/organisms/Support';

export interface Message {
  chatId: string;
  id: number;
  type: string;
  from: string;
  when: string;
  content: string | null;
  appointmentId: string | null;
  appointment: any;
}

export const Chat = ({ className }: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const {state, hash, search} = location;
  const queryParams = new URLSearchParams(search);

  const [isPatient, setIsPatient] = useState(false);
  const [chatObj, setChatObj] = useState<any>(null);
  const [user, setUser] = useState<any>(state?.user || null);
  const [myTherapist, setMyTherapist] = useState<any>(null);
  const [myTherapistToggle, setMyTherapistToggle] = useState(false);
  const [docSheetIsOpen, setDocSheetIsOpen] = useState(false);
  const [onboardingTherapist, setOnboardingTherapist] = useState(false);
  const [onboardingPatient, setOnboardingPatient] = useState(false);
  const [showStripeConnectWarning, setShowStripeConnectWarning] = useState(state?.showStripeConnectWarning);
  const [loadingModalIsOpen, setLoadingModalIsOpen] = useState(false);
  const [showDocuments, setShowDocuments] = useState(false);
  const [showSurveyAnswers, setShowSurveyAnswers] = useState(false);
  const {therapistData, setTherapistData, removeTherapistData} = useTherapistData();
  const [loadingToggle, setLoadingToggle] = useState(false);
  const [loadingChat, setLoadingChat] = useState(true);
  const [tabIndex, setTabIndex] = useState<number>(1);

  const chatId: any = queryParams.get('chatId');
  const isMobile: any = queryParams.has('mobile');
  const temporaryTherapistId: any = localStorage.getItem('temporaryTherapistId');

  const getMyTherapist = trpc.mainService.patient.getMyTherapist.useQuery(
    {therapistId: therapistData?.therapistId || undefined},
    {enabled: isPatient && !temporaryTherapistId}
  );

  const getUserInfo = trpc.mainService.user.getCurrentUserInfo.useQuery({therapistId: therapistData?.therapistId || undefined});

  const getMyTherapists: any = trpc.mainService.additionalAccounts.getAdditionalAccounts.useQuery(
    undefined,
    {enabled: isPatient}
  );

  const onboarding = trpc.mainService.user.completeOnboarding.useMutation();

  useEffect(() => {
    analyticsService({'event': 'virtualPageview', 'pageUrl': '/dashboard/chat', 'pageTitle': 'Chat'});
  }, []);

  useEffect(() => {
    if (temporaryTherapistId) {
      navigate(routes.ChooseTherapist.path);
    }
  }, [temporaryTherapistId]);

  const { data: chat, refetch: refetchChats } =
    trpc.mainService.chat.getChats.useQuery();

  useEffect(() => {
    if (isPatient && getMyTherapists.isFetched && getMyTherapists.isSuccess && !myTherapistToggle) {
      if (chatId) {
        if (!checkChatExistence(chatId)) {
          removeTherapistData()
          getMyTherapist.refetch()
          setMyTherapistToggle(true)
        } else {
          setMyTherapistToggle(true);
        }
      } else {
        setMyTherapistToggle(true);
      }
    }
  }, [isPatient, getMyTherapists.isFetched, getMyTherapists.isSuccess, loadingChat, user]);

  useEffect(() => {
    if (loadingChat) {
      if (!getUserInfo.isFetched && !getUserInfo.isSuccess) {
        setLoadingToggle(true);
      } else if (getUserInfo.isSuccess || user) {
        setLoadingToggle(false);
        setLoadingChat(false);
        setUser(getUserInfo?.data?.user || user);
        const userInfo: any = getUserInfo?.data?.user || user;
        const {role} = userInfo;
        if (role === 'patient') {
          setIsPatient(true);
          if (!userInfo.onboarding && userInfo.multiProfileOnboarding && userInfo?.therapyType !== 'underage') {
            setOnboardingPatient(true)
          }
          if (!getUserInfo?.data?.user?.therapistId) {
            navigate(routes.ChooseTherapist.path);
          }
          if (getUserInfo?.data?.user?.therapistId && temporaryTherapistId) {
            localStorage.removeItem('temporaryTherapistId');
            setLoadingToggle(true);
          }
        }
        if (role === 'therapist') {
          if (user?.onboarding || state?.onboarding) {
            setOnboardingTherapist(true);
          }
          setIsPatient(false);
          setMyTherapist(userInfo);
        }
      } else if (getUserInfo.status === 'error') {
        dispatch(setInfo({
          text: getUserInfo.error.message,
          variant: 'error'
        }));
      }
    }
  }, [user, state, getMyTherapists.isSuccess, getUserInfo.isSuccess, getUserInfo.isFetched, loadingChat]);

  const checkTherapistExistence = (therapistId) => {
    return getMyTherapists.data?.therapists.some((item) => item.id === therapistId);
  };

  const checkChatExistence = (chatId) => {
    return getMyTherapists.data?.therapists.some((item) => item.chat.id === chatId);
  };

  const completeOnboarding = () => {
    onboarding
      .mutateAsync()
      .then(async () => {
        await getUserInfo.refetch().then(() => {
          setLoadingChat(true);
        });
        setOnboardingPatient(false);
      })
      .catch((e) => {
        raiseException(e);
        console.error(e?.shape?.message)
      });
  };

  const startOnboarding = () => {
    setOnboardingPatient(false);
    navigate(routes.Chat.path, {state: {onboarding: true}});
  }

  const renderOnboardingPatientModal = () => (
    <Modal modal>
      <>
        <FormHeader
          title=""
          closeModal={completeOnboarding}
          closeButton
        ></FormHeader>
        <div className={styles.onboardingTitle}>Abbiamo novità per te!</div>
        <div>
          Nuovi percorsi e professionisti sono ora disponibili su Mymentis. Scopri come possiamo supportarti al meglio
          nel tuo percorso di benessere.
        </div>
        <Button className={styles.onboardingButton} variant="primary" onClick={startOnboarding}>Scopri le
          novità</Button>
      </>
    </Modal>
  )

  useEffect(() => {
    if (myTherapistToggle && getMyTherapists.isSuccess && getUserInfo.isSuccess && chat?.chats[0]?.id && getMyTherapist.isSuccess && !loadingModalIsOpen && !temporaryTherapistId) {
      setMyTherapistToggle(false);
      if (therapistData?.therapistId && !chatId) {
        if (!checkTherapistExistence(therapistData?.therapistId)) {
          getMyTherapists.refetch().then((result) => {
            const therapistExists = result.data?.therapists.some((item) => item.id === therapistData?.therapistId);
            if (therapistExists) {
              setMyTherapistToggle(true);
            } else {
              removeTherapistData()
              setMyTherapistToggle(true);
            }
          });
        }
        setMyTherapist(getMyTherapists.data?.therapists.find((item) => item.id === therapistData?.therapistId));

        if (!checkChatExistence(therapistData?.chat?.id)) {
          getMyTherapists.refetch().then((result) => {
            const chatIdExists = result.data?.therapists.some((item) => item.chat.id === therapistData?.chat?.id);
            if (chatIdExists) {
              setMyTherapistToggle(true);
            }
          });
        } else {
          setChatObj(therapistData?.chat);
        }
      } else {
        const therapist = checkChatExistence(chatId) ? getMyTherapists.data?.therapists.find((item) => item.chat.id === chatId) : getMyTherapist.data?.therapist;
        const chatObject: any = chat.chats.find((item) => checkChatExistence(chatId) ? item.id === chatId : item.therapist.id === therapist?.id);
        if (chatObject) {
          setTherapistData({
            therapistId: therapist?.id,
            chat: {
              id: chatObject?.id,
              therapyType: chatObject?.therapyType,
              createdAt: chatObject?.createdAt
            },
            therapyType: chatObject?.therapyType,
            changeTherapist: getUserInfo?.data?.user?.changeTherapist,
            main: getUserInfo?.data?.user?.therapistId === therapist?.id
          })
          setMyTherapist(therapist);
          setChatObj(chatObject);
        } else {
          refetchChats().then((result) => {
            const chat: any = result.data?.chats?.find((item) => chatId ? item.id === chatId : item.therapist.id === therapist?.id);
            if (chat) {
              setTherapistData({
                therapistId: therapist?.id,
                chat: {
                  id: chat?.id,
                  therapyType: chat?.therapyType,
                  createdAt: chat?.createdAt
                },
                therapyType: chat?.therapyType,
                changeTherapist: getUserInfo?.data?.user?.changeTherapist,
                main: getUserInfo?.data?.user?.therapistId === therapist?.id
              })
              setMyTherapist(therapist);
              setChatObj(chat);
            } else {
              return;
            }
          });
        }
      }
    } else if (getMyTherapists.status === 'error' || getMyTherapist.status === 'error') {
      setMyTherapistToggle(false);
      dispatch(
        setInfo({
          text: 'Qualcosa è andato storto, riprova più tardi.',
          variant: 'error',
        })
      );
    }
  }, [getMyTherapists.isSuccess, myTherapistToggle, chat, getMyTherapist.isSuccess, getUserInfo.isSuccess]);

  useEffect(() => {
    if (!getToken('accessToken')) {
      dispatch(
        setInfo({
          title: 'Utente non loggato',
          text: 'Eseguire il login',
          variant: 'error',
        })
      );
      const fullPath = hash ? `${hash}${search}` : `${location.pathname}${search}`;
      localStorage.setItem('redirectPath', fullPath);
      navigate(routes.Login.path)
    }
    Emitter.on('updateInfo', updateInfo);
  }, []);


  const updateInfo = async () => {
    try {
      setLoadingModalIsOpen(true)
      setUser(null)
      await getMyTherapists.refetch()
      await getMyTherapist.refetch()
      await refetchChats()
      setMyTherapistToggle(false)
      setLoadingChat(true)
    } catch (error: any) {
      raiseException(error);
      console.log('Error:', error)
    } finally {
      setLoadingModalIsOpen(false)
    }
  }

  const renderLoadingModal = () => (
    <Modal>
      <Spin indicator={<LoadingOutlined style={{fontSize: 32}} spin/>}/>
    </Modal>
  );

  const renderStripeWarningModal = () => (
    <Modal modal close={() => {
      setShowStripeConnectWarning(false);
      navigate(routes.Chat.path, {});
    }}>
        <FormHeader
          title="Attenzione"
          closeModal={() => {
            setShowStripeConnectWarning(false);
            navigate(routes.Chat.path, {});
          }}
          closeButton
        />
        <p className={styles.connectDescription}>
          Per poter ricevere pazienti, devi completare la configurazione
          dell'account Stripe Connect attraverso la sezione Pagamenti.
        </p>
        <Button
          onClick={() => {
            setShowStripeConnectWarning(false);
            navigate(routes.ProfileSection.links.payment);
          }}
          className={styles.stripeWarningButton}
        >
          Vai alla sezione pagamenti
        </Button>
    </Modal>
  );

  const handleTabChange = (tab: number) => {
    setTabIndex(tab);
  };

  const renderOnboardingModal = () => (
    <Modal>
      <div className={styles.therapistOnboarding}>
        <video width="612" controls>
          <source src="mov_bbb.mp4" type="video/mp4"/>
          <source src="mov_bbb.ogg" type="video/ogg"/>
          Your browser does not support HTML video.
        </video>
        <div className={styles.bottomPart}>
          <h5 className={styles.title}>Video tutorial Terapeuta</h5>
          <div className={styles.description}>
            In questo breve video ti mostreremo come utilizzare al meglio la
            piattaforma.
          </div>
          <div className={styles.description}>
            Puoi anche rivederlo in un secondo momento nella tua sezione
          </div>
        </div>
        <Button onClick={() => setOnboardingTherapist(false)}>
          Entra nella piattaforma
        </Button>
      </div>
    </Modal>
  );

  const renderChatContent = () => {
    if (!user) return renderLoadingModal();

    if ((user.role === 'therapist' || (user.role === 'patient' && chat?.chats[0]?.id && chatObj)) && myTherapist) {

      return (
        <ChatRoom
          docSheetIsOpen={docSheetIsOpen}
          className={`${styles.chatWrapper} ${isMobile && styles.mobile}`}
          myTherapist={myTherapist}
          user={user}
          chatId={chatId}
          showDocuments={showDocuments}
          setShowDocuments={setShowDocuments}
          showSurveyAnswers={showSurveyAnswers}
          setShowSurveyAnswers={setShowSurveyAnswers}
        />
      );
    }

    return renderLoadingModal();
  };

  const isMultiProfilePath = location.pathname === routes.MultiProfile.path;
  const isChatPath = location.pathname === routes.Chat.path && !loadingModalIsOpen;
  const isAppointmentsPath = location.pathname === routes.Appointments.path && getUserInfo.status === 'success';
  const isProfilePath = location.pathname === routes.Profile.path || location.pathname.includes(routes.Profile.path);
  const isClinicalPapersPath = location.pathname === routes.ClinicalPapers.path && myTherapist && user;
  const isDashboardFaqPath = location.pathname.includes(routes.DashboardFaq.path);

  return (
    <div className={`${styles.chatPage} ${className}`}>
      {loadingModalIsOpen && renderLoadingModal()}
      {showStripeConnectWarning && renderStripeWarningModal()}
      {onboardingTherapist && renderOnboardingModal()}
      {onboardingPatient && renderOnboardingPatientModal()}
      {myTherapist?.id && !loadingModalIsOpen && !isMobile && (
        <MobileHeader
          chatWith={isPatient ? `${myTherapist?.given_name} ${myTherapist?.family_name}` : ''}
          openProfile={setDocSheetIsOpen}
          profileIsOpen={docSheetIsOpen}
          profileDataId={myTherapist?.id}
          showDocuments={showDocuments}
          setShowDocuments={setShowDocuments}
          showSurveyAnswers={showSurveyAnswers}
          setShowSurveyAnswers={setShowSurveyAnswers}
          getUserInfo={getUserInfo}
          activeTab={tabIndex}
          changedTab={handleTabChange}
        />
      )}
      {isPatient && !loadingModalIsOpen && !isMobile && (
        <Support/>
      )}
      {isChatPath
        ? renderChatContent()
        : isAppointmentsPath
          ? <PatientAppointments userInfo={getUserInfo?.data?.user} activeTab={tabIndex} changedTab={handleTabChange}/>
          : isMultiProfilePath
            ? <Profile/>
          : isProfilePath
            ? <UserProfile/>
            : isClinicalPapersPath
              ? (
                <div className={styles.medicalDocumentsContainer}>
                  <div className={styles.name}>
                    {`${myTherapist?.given_name} ${myTherapist?.family_name}`}
                  </div>
                  <MedicalDocuments
                    myTherapist={myTherapist}
                    patient={user}
                    chat={chatObj}
                  />
                </div>
              )
              : isDashboardFaqPath
                ? <Faq/>
                : ''
      }
      {isPatient && myTherapist && location.pathname === routes.Chat.path && !loadingModalIsOpen && therapistData && (
        <DocSheet
          patientData={user}
          doc={myTherapist}
          className={`${styles.chatDocSheet} ${docSheetIsOpen && styles.open}`}
        />
      )}
    </div>
  );
};
