import { FC, useState } from 'react';
import styles from "./FileWidget.module.scss";
import Button from "components/atoms/Button";
import { trpc } from "utils/trpc";
import { setInfo } from '../../../store/slices/infoSlice';
import { raiseException } from '../../../utils/raiseException';
import { useAppDispatch } from '../../../customHooks/reduxHooks';
import VerifyDownloadFile from '../../organisms/VerifyDownloadFile';

interface FileWidgetProps {
  messageId: number;
  filename: string;
  date: string;
  patientId: string;
  myTherapist: any;
  email: any;
}

export const FileWidget: FC<FileWidgetProps> = ({messageId, filename, date, patientId, myTherapist, email}) => {
  const [verifyLogs, setVerifyLogs] = useState<any>(null);
  const [selectedDownloadFile, setSelectedDownloadFile] = useState<any>(null);
  const dispatch = useAppDispatch();

  const generateDownloadFileUrl =
    trpc.mainService.chat.generateDownloadFileUrl.useMutation();
  const downloadDocument = trpc.mainService.medicalDocuments.downloadDocument.useMutation();

  const handleSetVerifyLogs = (logs: any) => {
    setVerifyLogs(logs)
  }

  const handleDownloadDocument = async () => {
    try {
      const response = await downloadDocument.mutateAsync({
        email: email,
        patientId: patientId,
        therapistId: myTherapist?.id,
        filename: filename,
        fileUploadDate: date,
      });
      if (response) {
        setVerifyLogs(response.documentLog)
        dispatch(
          setInfo({
            text: 'Il codice per scaricare il documento è stato inviato alla vostra e-mail.',
            variant: 'info',
          })
        );
      }
    } catch (e) {
      console.error('Error downloading document:', e);
      raiseException(e);
      setInfo({
        text: 'Qualcosa è andato storto',
        variant: 'error',
      })
    }
  }


  const downloadFile = async () => {
    const {url, filename} = await generateDownloadFileUrl.mutateAsync({
      messageId,
    });
    setSelectedDownloadFile({fileUrl: url, filename, date})
    await handleDownloadDocument()
  }

  return (
    <div className={styles.fileWidget}>
      <div className={styles.widgetInfo}>
        <div>
          <svg
            width="44"
            height="44"
            viewBox="0 0 44 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="44" height="44" rx="22" fill="#DFEAFF" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.4001 12.4004C14.406 12.4004 13.6001 13.2063 13.6001 14.2004V29.8004C13.6001 30.7945 14.406 31.6004 15.4001 31.6004H28.6001C29.5942 31.6004 30.4001 30.7945 30.4001 29.8004V19.146C30.4001 18.6686 30.2105 18.2107 29.8729 17.8732L24.9273 12.9276C24.5897 12.59 24.1319 12.4004 23.6545 12.4004H15.4001ZM18.1001 22.6004C17.603 22.6004 17.2001 23.0033 17.2001 23.5004C17.2001 23.9974 17.603 24.4004 18.1001 24.4004H25.9001C26.3972 24.4004 26.8001 23.9974 26.8001 23.5004C26.8001 23.0033 26.3972 22.6004 25.9001 22.6004H18.1001ZM18.1001 26.2004C17.603 26.2004 17.2001 26.6033 17.2001 27.1004C17.2001 27.5974 17.603 28.0004 18.1001 28.0004H25.9001C26.3972 28.0004 26.8001 27.5974 26.8001 27.1004C26.8001 26.6033 26.3972 26.2004 25.9001 26.2004H18.1001Z"
              fill="#3576BB"
            />
          </svg>
        </div>

        <p className={styles.text}>{filename}</p>
      </div>
      <Button
        className={styles.widgetFileDownload}
        variant="tertiary"
        onClick={downloadFile}
      >
        Scarica
      </Button>
      {verifyLogs &&
        <VerifyDownloadFile
          verifyLogs={verifyLogs}
          myTherapist={myTherapist}
          selectedDownloadFile={selectedDownloadFile}
          setVerifyLogs={handleSetVerifyLogs}
          handleDownloadDocument={handleDownloadDocument}
        />
      }
    </div>
  );
};
