import { InfoCircleOutlined } from "@ant-design/icons";
import { VideoCameraIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Dispatch, useEffect, useState } from "react";
import { trpc } from "../../../utils/trpc";
import Button from "../../atoms/Button";
import ModifyAppointmentForm from "../ModifyAppointmentForm";
import styles from "./AppointmentCard.module.scss";
import { setInfo } from "store/slices/infoSlice";
import { useAppDispatch } from "customHooks/reduxHooks";
import { raiseException } from "../../../utils/raiseException";
import { analyticsService } from "../../../utils/analyticsService";
import PatientPayment from "../../organisms/PatientPayment";
import { getNormalizedDate } from "../../../utils/dateTime";
import { PaidAppointmentDisclaimerModal } from "../PaidAppointmentDisclaimerModal/PaidAppointmentDisclaimerModal";
import routes from "../../../routes";
import { disableAppointmentButton } from "../../../utils/disableAppointmentButton";
import Modal from '../../organisms/Modal';
import TherapistWarningButtonModal from '../../Modals/TherapistWarningButtonModal';
import { useNavigate } from 'react-router-dom';
import { useTherapistData } from '../../../customHooks/therapistDataContext';

interface Props {
  className?: string;
  appointment: any;
  role: "patient" | "therapist";
  isPsychiatry?: boolean;
  fromCalendar?: boolean;
  closeCard?: () => void;
  setModalIsVisible: Dispatch<React.SetStateAction<boolean>>;
  setModalChildren: Dispatch<any>;
  setModal?: any;
  modifyAppointment?: boolean
}

type Variant = "warn" | "info" | "confirm" | "error" | undefined;

export const AppointmentCard = ({
  className,
  appointment,
  role,
  isPsychiatry,
  fromCalendar,
  closeCard,
  setModalIsVisible,
  setModalChildren,
  setModal,
  modifyAppointment
}: Props) => {
  const startTime = new Date(appointment?.startTime).getTime();
  const navigate = useNavigate();
  const {therapistData} = useTherapistData();

  const [chat, setChat] = useState<any>(null);
  const dispatch = useAppDispatch();
  const [appointmentState, setAppointmentState] = useState<any>(null);
  const [isPast, setIsPast] = useState<boolean>();
  const [isPastPayedMoved, setIsPastPayedMoved] = useState<boolean>();
  const [isCompleted, setIsCompleted] = useState<boolean>();
  const [isModalPaymentOpen, setIsModalPaymentOpen] = useState(false);
  const [paymentModalChildren, setPaymentModalChildren] = useState<any>(null);
  const [isModalWarningOpen, setIsModalWarningOpen] = useState<boolean>(false);

  const { patient } = appointment!;

  const myChats = trpc.mainService.chat.getChats.useQuery().data;
  const getAppointments = trpc.mainService.appointment.getAppointments.useQuery(
    {}
  );

  const createdByPatient = appointment?.createdByPatient;
  const isFirstAppointment = appointment?.isFirstAppointment;
  const appointmentStatus = appointment?.status;
  const isPayedMoved = appointmentStatus === 'payed_moved'
  const paymentIntentId = appointment?.paymentIntentId;
  const isTherapist = role === 'therapist';
  const isPatient = role === 'patient';
  const commonConditions = appointment.moveRequest || isCompleted;

  const getCurrentUserInfoResponse =
    trpc.mainService.user.getCurrentUserInfo.useQuery({therapistId: therapistData?.therapistId || undefined});
  const getPaymentIntent =
    trpc.mainService.payments.stripe.getPaymentIntent.useMutation();

  const myTherapist = trpc.mainService.patient.getMyTherapist.useQuery(
    undefined,
    {
      enabled: isPatient,
    }
  ).data?.therapist;

  const dateDay = getNormalizedDate(startTime).toLocaleString("IT", {
    weekday: "short",
    day: "2-digit",
    month: "long",
  });

  const dateStartTime = getNormalizedDate(startTime).toLocaleString("IT", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const dateEndTime = getNormalizedDate(appointment?.endTime).toLocaleString("IT", {
    hour: "2-digit",
    minute: "2-digit",
  });

  const lastDayToPay = getNormalizedDate(startTime).toLocaleString("IT", {
    day: "2-digit",
    month: "long",
  });

  const lastTimeToPay = getNormalizedDate(startTime - 1000 * 60 * 15).toLocaleString(
    "IT",
    {
      hour: "2-digit",
      minute: "2-digit",
    }
  );
  const appointmentTherapyTypes = () => {
    switch (appointment?.therapyType) {
      case 'single':
        return 'Individuale';
      case 'couple':
        return 'Di coppia';
      case 'underage':
        return 'Minori';
      case 'psychiatry':
        return 'Psichiatria';
      default:
        return ''
    }
  };

  const expiredAppointmentButtonContentForPatient = () => {
    return {
      title: "Appuntamento Cancellato",
      text: (
        <div>
          L'appuntamento è stato cancellato perché non è stato confermato entro il tempo limite. {isPsychiatry && (
          <span style={{textDecoration: 'underline', cursor: 'pointer'}}
                onClick={() => {
                  navigate(routes.PatientBookingFirstAppointment.path);
                }}
          >
            Clicca qui
          </span>
        )} per prenotare un nuovo appuntamento.
        </div>
      ),
    };
  }

  const expiredAppointmentButtonContentForTherapist = () => {
    return {
      title: "Appuntamento Cancellato",
      text: (
        <div>
          L'appuntamento è stato cancellato perché non è stato confermato
          entro il tempo limite.{" "}
          <span onClick={() => onModify()} style={{textDecoration: "underline", cursor: "pointer"}}>
              Sposta
            </span>
        </div>
      ),
    };
  }

  const buttonsContentForTherapist = () => {
    if (createdByPatient) {
      return {
        buttonText: "Accetta",
        widgetText: "PRIMO APPUNTAMENTO",
        variant: "confirm" as Variant,
      };
    }
    if (isFirstAppointment !== null) {
      return {
        buttonText: "Collegati",
        widgetText: "PRIMO APPUNTAMENTO",
        variant: "confirm" as Variant,
      };
    } else {
      return {
        buttonText: "Collegati",
        widgetText: "IN ATTESA DI PAGAMENTO",
        variant: "warn" as Variant,
      };
    }
  }

  const getAppointmentState = (appointmentData: any) => {
    const isExpired = +getNormalizedDate(startTime) <= +getNormalizedDate();
    const getPatientState = () => {
      switch (appointmentStatus) {
        case "waiting_for_confirm":
          if (isExpired) {
            const { title, text} = expiredAppointmentButtonContentForPatient();
            return { title, text }
          }
          if (createdByPatient && paymentIntentId) {
            return { buttonText: "Conferma" };
          }
          return { buttonText: "Accetta" };
        case "payed_to_be_confirmed":
          if (isExpired) {
            const { title, text} = expiredAppointmentButtonContentForPatient();
            return { title, text }
          }
          return { buttonText: "Collegati" };
        case "ready":
          return { buttonText: "Collegati" };
        case "completed":
          return { buttonText: "Collegati" };
        case "moved":
          return {
            title: "Appuntamento Spostato",
            text: "Il terapeuta ha spostato questo appuntamento.",
          };
        case "cancelled":
          return {
            title: "Appuntamento Cancellato",
            text:
              appointmentData?.cancelledBy === "patient"
                ? "Hai scelto di cancellare l'appuntamento. Se vuoi puoi accordarti con il terapeuta per scegliere un altro giorno."
                : "Il tuo terapeuta ha scelto di cancellare l'appuntamento. Se vuoi puoi accordarti con lui per scegliere un altro giorno.",
          };
        case "expired":
          const { title, text} = expiredAppointmentButtonContentForPatient();
          return { title, text }
        case 'payed_moved':
          return {buttonText: 'Accetta'};
        default:
          return { buttonText: "Accetta" };
      }
    };

    const getTherapistState = () => {
      const { buttonText, widgetText, variant } = buttonsContentForTherapist();
      switch (appointmentStatus) {
        case "waiting_for_confirm":
          if (isExpired) {
            const { title, text} = expiredAppointmentButtonContentForTherapist();
            return { title, text }
          }
          return { buttonText, text: widgetText, variant }
        case "payed_to_be_confirmed":
          if (isExpired) {
            const { title, text} = expiredAppointmentButtonContentForTherapist();
            return { title, text }
          }
          return { buttonText, text: widgetText, variant }
        case "ready":
          return {
            buttonText: "Collegati",
            text: "PAGATO",
            variant: "confirm" as Variant,
          };
        case "completed":
          return {
            buttonText: "Collegati",
            text: "PAGATO",
            variant: "confirm" as Variant,
          };
        case "moved":
          return {
            title: "Appuntamento Spostato",
            text: "Hai scelto di spostare l'appuntamento.",
          };
        case 'payed_moved':
          return {
            buttonText: 'Collegati',
            variant: 'confirm' as Variant,
          };
        case "cancelled":
          return {
            title: "Appuntamento Cancellato",
            text:
              appointmentData?.cancelledBy === "therapist"
                ? "Hai scelto di cancellare l'appuntamento. Se vuoi puoi accordarti con il paziente per scegliere un altro giorno."
                : "Il paziente ha scelto di cancellare l'appuntamento. Se vuoi puoi accordarti con lui per scegliere un altro giorno.",
          };
        case "expired":
          const { title, text} = expiredAppointmentButtonContentForTherapist();
          return { title, text }
        default:
          return {};
      }
    };

    return isPatient ? getPatientState() : getTherapistState();
  };

  useEffect(() => {
    setAppointmentState(getAppointmentState(appointment));
    setIsPast(+getNormalizedDate(appointment.endTime) < +getNormalizedDate());
    setIsPastPayedMoved(+getNormalizedDate() > +getNormalizedDate(appointment.endTime) + 5 * 24 * 60 * 60 * 1000);

    const interval = setInterval(() => {
      setAppointmentState(getAppointmentState(appointment));
      setIsCompleted(
        +getNormalizedDate(appointment.endTime) + 1000 * 60 * 60 <
        +getNormalizedDate()
      );
      setIsPast(+getNormalizedDate(appointment.endTime) < +getNormalizedDate());
      setIsPastPayedMoved(+getNormalizedDate() > +getNormalizedDate(appointment.endTime) + 5 * 24 * 60 * 60 * 1000);
    }, 60000);
    if (modifyAppointment) {
      onModify();
    }
    return () => {
      clearInterval(interval);
    };
  }, [appointment])

  const onPatientPayment = async (appointment: any) => {
    const onPayAppointment = async () => {
      getAppointments.refetch();
    };
    const response = await getPaymentIntent.mutateAsync({
      appointmentId: appointment.id,
    });

    setPaymentModalChildren(
      <PatientPayment
        isModal
        className="modalFullHeight"
        closeModal={() => setIsModalPaymentOpen(false)}
        userInfo={getCurrentUserInfoResponse?.data?.user}
        myTherapist={myTherapist}
        savedPayment={
          getCurrentUserInfoResponse.data?.user?.stripeDefaultPaymentMethodId ??
          undefined
        }
        setupFutureUsage={Boolean(response.setupFutureUsage)}
        onPayAppointment={onPayAppointment}
        paymentIntentId={response.paymentIntentId}
        clientSecret={response.clientSecret!}
        amount={response.amount / 100}
        appointment={appointment}
      />
    );
    setIsModalPaymentOpen(true);
  };

  const acceptAppointment =
    trpc.mainService.appointment.acceptFreeAppointment.useMutation();

  const acceptPayedMovedAppointment = trpc.mainService.appointment.acceptPayedMovedAppointment.useMutation();

  const errorHandler = (error: any) => {
    raiseException(error);
    dispatch(
      setInfo({
        text: "Si è verificato un errore",
        variant: "error",
      })
    );
  };

  const onAccept = (appointmentId: string, isPayedMoved: boolean = false) => {
    const acceptMethod = isPayedMoved ? acceptPayedMovedAppointment : acceptAppointment;

    acceptMethod
      .mutateAsync({appointmentId, accountTherapyType: therapistData?.terapyType})
      .then((data) => {
        getAppointments.refetch();
        if (data.appointment) {
          const date = getNormalizedDate(data.appointment.startTime).toLocaleDateString(
            "IT",
            {
              day: "2-digit",
              month: "long",
              year: "numeric",
            }
          );
          const time = getNormalizedDate(data.appointment.startTime).toLocaleString(
            "IT",
            {
              hour: "2-digit",
              minute: "2-digit",
            }
          );
          setModal(date, time)
          // socket.emit("updateWidgetMessage", messageId, chat.id);
        }
      })
      .catch((e) => {
        errorHandler(e);
      });
  };

  const onModify = () => {
    setModalChildren(
      <ModifyAppointmentForm
        closeModal={() => setModalIsVisible(false)}
        chat={chat?.id!}
        patientId={patient?.id}
        appointment={appointment}
        onRequestMove={() => {
          getAppointments.refetch();
          closeCard && closeCard();
        }}
        onMove={() => {
          // onUpdate();
          getAppointments.refetch();
          closeCard && closeCard();
        }}
        onCancel={() => {
          // onUpdate();
          getAppointments.refetch();
        }}
        showCancelDisclaimer={showDisclaimer}
        isPsychiatry={isPsychiatry}
      />
    );
    setModalIsVisible(true);
  };

  const showDisclaimer = (message: string) => {
    setModalChildren(
      <PaidAppointmentDisclaimerModal
        closeModal={() => setModalIsVisible(false)}
        appointment={appointment}
        message={message}
        chatId={chat?.id!}
        onModifyAppointmentInAppointments={onModify}
        onCancel={() => getAppointments.refetch()}
        page={'appointment'}
      />
    )
    setModalIsVisible(true);
  }

  useEffect(() => {
    if (myChats) {
      setChat(myChats.chats.find((c) => c.patient?.id === patient?.id));
    }
  }, [myChats]);

  const onReady = (id: string) => {
    const sessionType = isFirstAppointment && !isPsychiatry ? 'free' : 'paid';
    const userRole = isTherapist ? 'therapist' : 'patient';
    const event = `${sessionType}_session_room_${userRole}`;

    analyticsService({ event, appointmentID: id });

    window.open(`/${routes.WaitingRoom.path.split("/")[1]}/${id}`, "_blank");
  };

  const isDisabled = disableAppointmentButton(paymentIntentId, isTherapist, isPatient, createdByPatient, appointmentStatus, commonConditions, isPast);
  const isHidden = isTherapist && (appointmentStatus === 'waiting_for_confirm' && createdByPatient && paymentIntentId);

  const appointmentStatusTexts = () => {
    return (
      <>
        {appointmentStatus === 'ready' ? (
          <div className={styles.paid}>
            <InfoCircleOutlined/> {isFirstAppointment && !isPsychiatry ? 'PRIMO APPUNTAMENTO' : 'PAGATO'}
          </div>
        ) : appointmentStatus === 'payed_to_be_confirmed' ? (
          <div className={styles.toPay}>
            <InfoCircleOutlined />
            {isPatient
              ? " In attesa di conferma del professionista"
              : " Confermato e pagato dal paziente, da confermare"
            }
          </div>
        ) : isPayedMoved ? (
          <div className={styles.toPay}>
            <InfoCircleOutlined /> Ci dispiace, il medico ha dovuto spostare l’appuntamento per un’urgenza.<br/>Puoi accettarlo o concordare uno nuovo in chat.
          </div>
        ) : appointmentStatus === 'completed' ? (
          <div className={styles.paid}>
            <InfoCircleOutlined /> Completato
          </div>
        ): appointmentStatus === 'expired' ? (
          <div className={styles.toPay}>
            <InfoCircleOutlined /> Cancellato
          </div>
        ) : isPatient ? (
          isFirstAppointment && !isPsychiatry ? (
            <div className={styles.toPay}>
              <InfoCircleOutlined/> ACCETTA ENTRO LE {lastTimeToPay} DEL{' '}
              {lastDayToPay}
            </div>
          ) : (
            <div className={styles.toPay}>
              <InfoCircleOutlined /> PAGA ENTRO LE {lastTimeToPay} DEL{" "}
              {lastDayToPay}
            </div>
          )
        )  : (
          <div className={styles.toPay}>
            <InfoCircleOutlined/>{isFirstAppointment && !isPsychiatry ? ' In attesa di conferma' : ' In attesa di pagamento'}
          </div>
        )}
      </>
    )
  }

  const handleAppointmentClick = () => {
    switch (appointmentStatus) {
      case 'payed_to_be_confirmed':
        if (createdByPatient) {
          return onAccept(appointment.id);
        }
        break;
      case 'waiting_for_confirm':
        if (isFirstAppointment !== null && !isPsychiatry) {
          return onAccept(appointment.id);
        }
        if (isHidden) {
          return setIsModalWarningOpen(true);
        }
        return onPatientPayment(appointment);
      case 'ready':
      case 'completed':
        return onReady(appointment.id);
      case 'payed_moved':
        return onAccept(appointment.id, true);
    }
  };

  return (
    <div className={`${styles.appointmentCard} ${className}`}>
      {isModalPaymentOpen && <Modal>{paymentModalChildren}</Modal>}
      {isModalWarningOpen && <TherapistWarningButtonModal setClose={() => setIsModalWarningOpen(false)}/>}
      {fromCalendar && (
        <div className={styles.closeBtnWrapper}>
          <XMarkIcon className={styles.closeBtn} onClick={closeCard} />
        </div>
      )}
      {isTherapist && (
        <div className={styles.patientName}>
          {patient?.given_name} {patient?.family_name}{" "}
          {!patient && "Paziente eliminato"}
        </div>
      )}
      <div className={styles.topPartMobile}>
        <div className={styles.nameDateWrapper}>
          <div className={styles.date}>
            <div className={styles.dateWrapper}>
              <span>{dateDay}</span>
              <span>
                {dateStartTime} - {dateEndTime}
              </span>
            </div>
            <div className={styles.appointmentStatusMobile}>
              {appointmentStatusTexts()}
              {appointment?.moveRequest && (
                <div
                  className={`${styles.moveRequest} ${styles.moveRequestMobile}`}
                >
                  <InfoCircleOutlined /> RICHIESTO SPOSTAMENTO
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.topPart}>
        <div className={styles.nameDateWrapper}>
          <div className={styles.date}>
            <div>
              {dateDay} | {dateStartTime} {isFirstAppointment && isPatient ? '' : ' - ' + dateEndTime}
            </div>
            {appointmentStatusTexts()}
          </div>
        </div>
        {(getAppointmentState(appointment)?.buttonText ||
            appointmentState?.buttonText) &&
          <Button
            className={isHidden && styles.hidden}
            disabled={isDisabled}
            onClick={() => handleAppointmentClick()}
            leftIcon={
              appointmentStatus === 'ready' ? (
                <VideoCameraIcon/>
              ) : null
            }
          >
            {getAppointmentState(appointment)?.buttonText ||
              appointmentState?.buttonText}
          </Button>
        }
      </div>
      {isPatient &&
        <div className={`${styles.middlePart} ${className}`}>
          {appointment?.therapist.given_name} {appointment?.therapist.family_name} - {appointmentTherapyTypes()}
        </div>}
      {appointment.moveRequest && (
        <div className={`${styles.moveRequest} ${styles.moveRequestDesktop}`}>
          <InfoCircleOutlined /> RICHIESTO SPOSTAMENTO
        </div>
      )}
      <div className={styles.bottomPart}>
        <div className={styles.titleInfoCard}>Hai un imprevisto?</div>
        <div className={styles.textInfoCard}>
          Se non riesci a partecipare all’appuntamento puoi spostarlo o
          cancellarlo.
        </div>
        {appointmentStatus !== 'completed' && (
          <Button
            disabled={isTherapist && appointmentStatus === 'payed_moved' ? isPastPayedMoved : isPast || appointmentStatus === 'payed_moved'}
                  onClick={() => onModify()} variant="tertiary">
            Sposta o cancella appuntamento
          </Button>
        )}
      </div>
      {(getAppointmentState(appointment)?.buttonText ||
          appointmentState?.buttonText) &&
        <Button
          className={`${styles.mobileBtn} ${isHidden && styles.hidden}`}
          disabled={isDisabled}
          onClick={() => handleAppointmentClick()}
          leftIcon={
            appointmentStatus === 'ready' ? (
              <VideoCameraIcon/>
            ) : null
          }
        >
          {getAppointmentState(appointment)?.buttonText ||
            appointmentState?.buttonText}
        </Button>
      }
    </div>
  );
};
