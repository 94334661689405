import styles from './Support.module.scss';
import { MailOutlined } from '@ant-design/icons';
import FormHeader from '../../atoms/FormHeader';
import Modal from '../Modal';
import { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { raiseException } from '../../../utils/raiseException';
import { trpc } from '../../../utils/trpc';
import Text from '../../atoms/Text';
import Input from '../../atoms/Input';
import Button from '../../atoms/Button';
import { setInfo } from '../../../store/slices/infoSlice';
import { useAppDispatch } from '../../../customHooks/reduxHooks';
import { getToken } from 'utils/getToken';
import { Tooltip } from 'antd';
import { getNormalizedDate } from '../../../utils/dateTime';
import { envs } from '../../../utils/envs';
import routes from '../../../routes';
import { useLocation } from 'react-router-dom';

export const Support = () => {
  const [open, setOpen] = useState(false);

  const dispatch = useAppDispatch();
  const location = useLocation();

  const token = !!getToken('accessToken')
  const path = location.pathname.split('/')[1];

  const excludeRoutes = [
    routes.TherapistRegister.path.split('/')[1],
    routes.PsychotherapistsRegister.path.split('/')[1],
  ];


  const requestSupport = trpc.mainService.requestSupport.useMutation();

  const formik = useFormik({
    initialValues: {
      givenName: '',
      familyName: '',
      email: '',
      text: '',
    },
    validationSchema: yup.object().shape({
      givenName: yup.string().required('Il nome è un campo obbligatorio'),
      familyName: yup.string().required('Il cognome è un campo obbligatorio'),
      email: yup
        .string()
        .trim()
        .matches(
          /^(?!\.)(?!.*\.\.)([A-Z0-9_+-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i,
          'L\'email inserita ha un formato non valido'
        )
        .required('L\'email è un campo obbligatorio'),
      text: yup
        .string()
        .required(
          'Specifica un problema per poter inoltrare la richiesta di supporto'
        ),
    }),
    onSubmit: async (values, actions) => {
      try {
        await requestSupport.mutateAsync({
          givenName: values.givenName,
          familyName: values.familyName,
          email: values.email,
          content: values.text,
          fromWhere: 'support',
        });
        dispatch(
          setInfo({
            variant: 'confirm',
            text: 'Richiesta di supporto inviata con successo',
          })
        );
        actions.resetForm();
        setOpen(false);
      } catch (e: any) {
        raiseException(e);
        console.error('ERROR', e?.shape?.message);
        dispatch(
          setInfo({
            variant: 'error',
            text: 'Si è verificato un errore durante l\'invio della richiesta di supporto',
          })
        );
      }
    },
  });

  const isDay = (() => {
    const currentDate = getNormalizedDate();
    const hours = currentDate.getHours();
    const day = currentDate.getDay();
    if (day >= 1 && day <= 4) {
      return hours >= 9 && hours < 18;
    }
    if (day === 5) {
      return hours >= 9 && hours < 16;
    }
    return false;
  })();

  const phoneNumber = envs.REACT_APP_WHATSAPP_SUPPORT_PHONE_NUMBER;

  const handleClick = () => {
    isDay ? window.open('https://wa.me/' + phoneNumber, '_blank', 'noreferrer') : setOpen(prev => !prev)
  }

  return (
    !excludeRoutes.includes(path) ? <div>
      {open && <Modal modal close={setOpen}>
        <>
          <FormHeader
            title="Contattaci"
            closeModal={() => setOpen(false)}
            closeButton
          ></FormHeader>
          <form className={styles.form} onSubmit={formik.handleSubmit}>
            <div className={styles.inputName}>
              <Text type="span" children="Nome"/>
              <Input
                placeholder="Nome"
                name="givenName"
                value={formik.values.givenName}
                onChange={formik.handleChange}
                error={
                  formik.errors.givenName && formik.touched.givenName
                    ? formik.errors.givenName
                    : undefined
                }
              />
            </div>
            <div className={styles.inputSurname}>
              <Text type="span" children="Cognome"/>
              <Input
                placeholder="Cognome"
                name="familyName"
                value={formik.values.familyName}
                onChange={formik.handleChange}
                error={
                  formik.errors.familyName && formik.touched.familyName
                    ? formik.errors.familyName
                    : undefined
                }
              />
            </div>
            <div className={styles.inputEmail}>
              <Text type="span" children="Email"/>
              <Input
                placeholder="Email"
                name="email"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={
                  formik.errors.email && formik.touched.email
                    ? formik.errors.email
                    : undefined
                }
              />
            </div>
            <div className={styles.textArea}>
              <Input
                placeholder="Scrivi qui il tuo problema"
                textarea
                name="text"
                value={formik.values.text}
                onChange={formik.handleChange}
                error={
                  formik.errors.text && formik.touched.text
                    ? formik.errors.text
                    : undefined
                }
              />
            </div>
            <Button
              disabled={
                !formik.isValid ||
                Object.values(formik.values).some((value) => value === '')
              }
            >
              Invia richiesta
            </Button>
          </form>
        </>
      </Modal>}
      <Tooltip placement="left" title={isDay ? 'Contatta l’assistenza' : 'Contattaci'}>
        <div onClick={handleClick} className={`${styles.supportWrapper} ${isDay ? styles.day : styles.night}`}>
          {isDay ? <img alt="" src="/assets/images/call-center.webp"/> : <MailOutlined/>}
        </div>
      </Tooltip>
    </div> : null
  );
};
