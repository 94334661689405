import { useEffect, useState } from "react";
import Input from "../../components/atoms/Input";
import GenericForm from "../../components/molecules/GenericForm";
import styles from "./ConfirmTherapist.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/atoms/Button";
import { trpc } from "utils/trpc";
import routes from "routes";
import { Alert } from "antd";
import { useDispatch } from "react-redux";
import { setInfo } from "store/slices/infoSlice";
import { analyticsService } from "../../utils/analyticsService";
import { raiseException } from "../../utils/raiseException";

export const ConfirmTherapist = ({ className = "" }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const {state, search} = location;
  const [error, setError] = useState<string>();

  const emailMfaChallenge =
    trpc.authService.auth.emailMfaChallenge.useMutation();

  const resendLoginOtp = trpc.authService.auth.resendLoginOtp.useMutation();

  const queryParams = new URLSearchParams(search);
  const queryEmail = queryParams.get('email');
  const querySession = queryParams.get('session');

  const email = state?.email || queryEmail;
  const session = state?.session || querySession;
  const hash = state?.hash;

  useEffect(() => {
    analyticsService({'event': 'virtualPageview', 'pageUrl': '/login/2fa', 'pageTitle': 'Confirm Therapist'});
  }, []);

  useEffect(() => {
    if (!email || !session) {
      navigate(routes.Login.path);
    }
  }, [email, session, navigate]);

  const confirmEmailFunction = async () => {
    try {
      const response = await emailMfaChallenge.mutateAsync({
        email,
        session,
        code: confirmCode,
      });

      // document.cookie = `accessToken=${response.accessToken}; Path=/;`;
      // document.cookie = `refreshToken=${response.refreshToken}; Path=/;`;
      localStorage.setItem("accessToken", response?.accessToken!);
      localStorage.setItem("refreshToken", response?.refreshToken!);
      localStorage.setItem("role", "therapist");

      if (response.showStripeConnectWarning) {
        return navigate(routes.Chat.path, {
          state: {
            showStripeConnectWarning: response.showStripeConnectWarning,
          },
        });
      }

      if (state?.redirectPath) {
        return navigate(state.redirectPath);
      }

      if (hash) {
        const pathToRedirectInB64 = hash.slice(1, hash.length);
        const pathToRedirect = atob(pathToRedirectInB64);

        return navigate(pathToRedirect);
      } else {
        return navigate(routes.Chat.path);
      }
    } catch (e: any) {
      if (e?.message === "Wrong code") {
        setError("wrongCode");
      } else if (e?.message === "Expired code") {
        setError("expiredCode");
      } else {
        setError("unknown");
      }
      raiseException(e);
      console.error(e);
    }
  };

  const resendCodeFunction = async () => {
    try {
      await resendLoginOtp
        .mutateAsync({
          email,
          session,
        })
        .then(() => {
          dispatch(
            setInfo({ text: "Codice inviato con successo", variant: "confirm" })
          );
        });
    } catch (e: any) {
      dispatch(
        setInfo({
          text: "Qualcosa è andato storto, riprovare",
          variant: "error",
        })
      );
      raiseException(e);
      console.error(e);
    }
  };

  const [confirmCode, setConfirmCode] = useState("");
  return (
    <div className={`${styles.confirmCode} ${className}`}>
      <GenericForm
        className={styles.confirmCodeForm}
        title={"Conferma la tua identità"}
        description={`Ti abbiamo inviato una mail. Per proseguire, inserisci il codice che trovi al suo interno`}
        answer={[
          <div className={styles.formInputs}>
            <Input
              className={styles.codeInput}
              value={confirmCode}
              onChange={(e) => {
                setError("");
                setConfirmCode(e.target.value);
              }}
              error={
                error === "wrongCode"
                  ? "Il codice inserito non è corretto"
                  : undefined
              }
              placeholder="Inserisci il codice"
            />
            {error && error !== "wrongCode" && (
              <Alert
                className={styles.alertError}
                type="error"
                message={
                  error === "expiredCode" ? (
                    <div>
                      Il link per l'autenticazione a due fattori è scaduto.{" "}
                      <span
                        className={styles.underlinedLink}
                        onClick={() => navigate(routes.Login.path)}
                      >
                        Clicca qui
                      </span>{" "}
                      per rieseguire la login e chiederne un altro.
                    </div>
                  ) : (
                    "Si è verificato un errore inaspettato. Verifica di essere connesso a internet e riprova."
                  )
                }
                closable
              />
            )}
          </div>,
        ]}
        cta={[
          <div>
            <Button
              onClick={() => confirmEmailFunction()}
              disabled={confirmCode.trim() === ""}
            >
              Verifica e accedi
            </Button>
            <div className={styles.confirmCodeBtn}>
              Non hai ricevuto il codice?{" "}
              <span
                className={styles.spanBtn}
                onClick={() => resendCodeFunction()}
              >
                Invia un altro codice
              </span>
            </div>
          </div>,
        ]}
      />
    </div>
  );
};
