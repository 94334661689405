export const checkingTimePassed = ({chat, hours = 0, minutes = 0} ): boolean => {
  if (chat) {
    const accountCreationDate = new Date(chat.createdAt);
    const currentDate = new Date();

    const timeInMS = (hours * 60 * 60 * 1000) + (minutes * 60 * 1000);

    return (currentDate.getTime() - accountCreationDate.getTime()) > timeInMS;
  }

  return false;
};
