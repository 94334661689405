import styles from './VerifyDownloadFile.module.scss';
import Modal from '../Modal';
import FormHeader from '../../atoms/FormHeader';
import GenericForm from '../../molecules/GenericForm';
import Input from '../../atoms/Input';
import { Alert } from 'antd';
import Button from '../../atoms/Button';
import { FC, useState } from 'react';
import { setInfo } from '../../../store/slices/infoSlice';
import { raiseException } from '../../../utils/raiseException';
import { trpc } from '../../../utils/trpc';
import { useAppDispatch } from '../../../customHooks/reduxHooks';

interface VerifyDownloadFileProps {
  verifyLogs: any;
  myTherapist: any;
  selectedDownloadFile: any;
  setVerifyLogs: any;
  handleDownloadDocument: any;
}

export const VerifyDownloadFile: FC<VerifyDownloadFileProps> = ({
  verifyLogs,
  myTherapist,
  selectedDownloadFile,
  setVerifyLogs,
  handleDownloadDocument
}) => {
  const [confirmCode, setConfirmCode] = useState('');
  const [error, setError] = useState<string>('');
  const [resendTimer, setResendTimer] = useState(0);
  const [isResendDisabled, setIsResendDisabled] = useState(false);

  const dispatch = useAppDispatch();

  const verifyDocumentDownloadCode = trpc.mainService.medicalDocuments.verifyDocumentDownloadCode.useMutation();

  const downloadFile = (url: string, filename: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename || 'file');
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const handleVerifyDocumentDownloadCode = async () => {
    if (confirmCode && verifyLogs.id && selectedDownloadFile.fileUrl) {
      try {
        const response = await verifyDocumentDownloadCode.mutateAsync({
          logsId: verifyLogs.id,
          code: confirmCode,
          email: myTherapist.email,
        });
        if (response.success) {
          try {
            let fileUrl = selectedDownloadFile.fileUrl;
            if (!fileUrl.includes('blob')) {
              const fetchResponse = await fetch(fileUrl);
              const blob = await fetchResponse.blob();
              fileUrl = URL.createObjectURL(blob);
            }

            downloadFile(fileUrl, selectedDownloadFile.filename);

            if (!selectedDownloadFile.fileUrl.includes('blob')) {
              URL.revokeObjectURL(fileUrl);
            }
            setVerifyLogs(null);
            dispatch(
              setInfo({
                text: 'Il codice è stato applicato con successo e il download del file è iniziato.',
                variant: 'confirm',
              })
            );
          } catch (e) {
            console.error('Error downloading document:', e);
            raiseException(e);
            dispatch(
              setInfo({
                text: 'Qualcosa è andato storto',
                variant: 'error',
              })
            );
          }
        } else {
          setVerifyLogs(null);
          dispatch(
            setInfo({
              text: 'È stato inserito tre volte il codice sbagliato, si prega di riprovare.',
              variant: 'warn',
            })
          );
        }
      } catch (e: any) {
        if (e?.message === 'Wrong code') {
          setError('wrongCode');
        } else if (e?.message === 'Expired code') {
          setError('expiredCode');
        } else {
          setError('unknown');
        }
        raiseException(e);
        console.error(e);
      }
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    setConfirmCode(value.slice(0, 6));
    setError('');
  };

  const resendCodeFunction = async () => {
    if (isResendDisabled) return;

    setError('');
    setConfirmCode('');
    await handleDownloadDocument();

    setIsResendDisabled(true);
    setResendTimer(60);

    const interval = setInterval(() => {
      setResendTimer((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          setIsResendDisabled(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };


  return (
    <Modal modal subClassName={styles.confirmCodeModal}>
      <FormHeader
        title="Inserire il codice"
        closeModal={() => {
          setVerifyLogs(null);
        }}
        closeButton
      />
      <GenericForm
        className={styles.confirmCodeForm}
        title=""
        description="Ti abbiamo inviato una mail. Per proseguire, inserisci il codice che trovi al suo interno:"
        answer={[
          <div className={styles.formInputs}>
            <Input
              className={styles.codeInput}
              value={confirmCode}
              onChange={handleInputChange}
              error={error === 'wrongCode' ? 'Il codice inserito non è corretto' : undefined}
              placeholder="Inserisci il codice"
              maxLength={6}
            />
            {error && error !== 'wrongCode' && (
              <Alert
                className={styles.alertError}
                type="error"
                message={
                  error === 'expiredCode' ? (
                    <div>
                      Il codice per scaricare il file è scaduto.{' '}
                      <span
                        className={styles.underlinedLink}
                        onClick={resendCodeFunction}
                      >
                        Clicca qui
                      </span>{' '}
                      per richiedere nuovamente un nuovo codice.
                    </div>
                  ) : (
                    'Si è verificato un errore inaspettato. Verifica di essere connesso a internet e riprova.'
                  )
                }
                closable
              />
            )}
          </div>,
        ]}
        cta={[
          <div>
            <Button onClick={handleVerifyDocumentDownloadCode} disabled={confirmCode.length !== 6}>
              Verifica e scarica il documento.
            </Button>
            <div className={styles.confirmCodeBtn}>
              Non hai ricevuto il codice?{' '}
              <span
                className={`${styles.spanBtn} ${isResendDisabled ? styles.disabled : ''}`}
                onClick={!isResendDisabled ? resendCodeFunction : undefined}
              >
              {isResendDisabled ? `Invia un altro codice (${resendTimer}s)` : 'Invia un altro codice'}
            </span>
            </div>
          </div>,
        ]}
      />
    </Modal>
  );
};
