import { Input, Spin } from 'antd';
import { useRef, useState } from 'react';
import { trpc } from "../../../utils/trpc";
import Button from "../../atoms/Button";
import FormHeader from "../../atoms/FormHeader";
import Tabs from "../../atoms/Tabs";
import AppointmentCalendar from "../../organisms/AppointmentCalendar";
import styles from "./ModifyAppointmentForm.module.scss";
import { Socket } from "socket.io-client";
import { useRouteLoaderData } from "react-router-dom";
import { raiseException } from "../../../utils/raiseException";
import { setInfo } from "../../../store/slices/infoSlice";
import { useAppDispatch } from "../../../customHooks/reduxHooks";
import { getNormalizedDate } from "../../../utils/dateTime";
import { useTherapistData } from '../../../customHooks/therapistDataContext';
import { LoadingOutlined } from '@ant-design/icons';

interface Props {
  closeModal: () => void;
  onPatientRequestMove?: () => void;
  chat: any;
  setMessage?: any;
  sendModifyMessage?: (message: string) => void;
  patientId?: string;
  appointment?: any;
  onCancel?: () => void;
  onMove?: () => void;
  onRequestMove?: () => void;
  showCancelDisclaimer?: (message: string) => void;
  isPsychiatry?: boolean;
}

export const ModifyAppointmentForm = ({
  closeModal,
  onPatientRequestMove,
  chat,
  patientId,
  appointment,
  onCancel,
  onMove,
  onRequestMove,
  showCancelDisclaimer,
  isPsychiatry
}: Props) => {
  const inputRef = useRef(null);
  const socket = useRouteLoaderData("root") as Socket;
  const dispatch = useAppDispatch();
  const {therapistData} = useTherapistData();

  const [message, setValue] = useState<string>("");
  const [activeSection, setActiveSection] = useState<"move" | "cancel">("move");

  const getAppointment: any = trpc.mainService.appointment.getAppointment.useQuery({
    appointmentId: appointment.id,
  }, {enabled: !!appointment.id});

  const startTime = getNormalizedDate(getAppointment?.data?.appointment.startTime);
  const today = getNormalizedDate(new Date());
  const isExpired = startTime <= today;

  const userInfo = trpc.mainService.user.getCurrentUserInfo.useQuery({therapistId: therapistData?.therapistId || undefined}).data;

  const askToMove =
    trpc.mainService.appointment.askMoveAppointment.useMutation();

  const cancelAppointment =
    trpc.mainService.appointment.cancelAppointment.useMutation();

  const createWidgetMessage =
    trpc.mainService.appointment.createWidgetMessage.useMutation();

  const deleteAppointment = trpc.mainService.appointment.deleteAppointment.useMutation();

  const therapyType = therapistData?.therapyType || userInfo?.user.therapyType;

  const showMessage = async () => {
    if (userInfo?.user.role === "patient") {
      askToMove.mutateAsync({appointmentId: getAppointment?.data?.appointment?.id}).then(() => {
        socket.emit(
          "sendTextMessage",
          {
            type: "text",
            content: `&moved ${userInfo.user.given_name} ${
              userInfo.user.family_name
            } ha richiesto di spostare la seduta di ${getNormalizedDate(
              getAppointment?.data?.appointment.startTime
            ).toLocaleString("IT", {
              weekday: "long",
              day: "2-digit",
              month: "long",
            })} alle ore ${getNormalizedDate(getAppointment?.data?.appointment.startTime).toLocaleString(
              "IT",
              {
                hour: "2-digit",
                minute: "2-digit",
              }
            )} per il seguente motivo:&moved ${message}`,
          },
          chat.id
        );
        if (onPatientRequestMove) {
          onPatientRequestMove();
        }
        if (onRequestMove) {
          onRequestMove();
        }
      });
    } else {
      try {
        const widget: any = await createWidgetMessage.mutateAsync({
          chatId: chat?.id,
          type: "widget",
          appointmentId: getAppointment?.data?.appointment?.id
        });

        if (widget?.appointmentData?.id) {
          socket.emit(
            "sendWidgetMessage",
            widget?.newMessage,
            widget?.appointmentData,
            widget?.chat
          );
        }
      } catch (error) {
        try {
          await deleteAppointment.mutateAsync({appointmentId: getAppointment?.data?.appointment?.id});
        } catch {}
        dispatch(
          setInfo({
            text: "Si è verificato un errore, prova a creare nuovamente l'appuntamento.",
            variant: "error",
          })
        );
      }
    }
    closeModal();
  };

  const onCancelAppointment = async () => {
    if (((getAppointment?.data?.appointment.status === 'payed_to_be_confirmed' && appointment.paymentIntentId) || (appointment.status === 'ready' && appointment.paymentIntentId))
          && userInfo?.user.role !== "patient") {
      if (showCancelDisclaimer) {
        showCancelDisclaimer(message);
      }
    } else {
      await makeAppointmentCancel();
    }
  };

  const handleError = (error: any) => {
    raiseException(error);
    console.error('ERROR', error);

    let text: any;
    if (isExpired) {
      text = (
        <div>
          Non è possibile cancellare o spostare l'appuntamento perché è già iniziato.
          In caso di necessità, puoi contattare l'assistenza scrivendo a{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="mailto:support@mymentis.it"
          >
            support@mymentis.it
          </a>.
        </div>
      );
    } else {
      text = 'Impossibile cancellare un appuntamento quando è già stato annullato, spostato o scaduto';
    }

    dispatch(
      setInfo({
        text: text,
        variant: 'error',
      })
    );
  };

  const makeAppointmentCancel = async () => {
    try {
      await getAppointment.refetch();
      const {message: successfullyCancelMessage} = await cancelAppointment.mutateAsync({appointmentId: getAppointment?.data?.appointment.id})
      if (successfullyCancelMessage) {
        socket.emit(
          "sendTextMessage",
          {
            type: "text",
            content: `&moved ${userInfo?.user.given_name} ${
              userInfo?.user.family_name
            } ha cancellato la seduta di ${getNormalizedDate(
              getAppointment?.data?.appointment.startTime
            ).toLocaleString("IT", {
              weekday: "long",
              day: "2-digit",
              month: "long",
            })} alle ore ${getNormalizedDate(getAppointment?.data?.appointment.startTime).toLocaleString(
              "IT",
              {
                hour: "2-digit",
                minute: "2-digit",
              }
            )} per il seguente motivo:&moved ${message}`,
          },
          chat?.id
        );
        if (onCancel) {
          onCancel();
        }
        closeModal();
      }
    } catch (error: any) {
      handleError(error);
    }
  }

  const moveCancelAppointmentTitleText = () => {
    if (isPsychiatry) {
      return (
        <p>
          Indica qui sotto il motivo e altre disponibilità così lo
          psichiatra potrà inviarti un nuovo appuntamento.
          <span style={{ fontWeight: 600 }}>
            {" "}
            Questo messaggio verrà inviato in chat allo psichiatra.
          </span>
        </p>
      )
    } else {
      return (
        <p>
          Indica qui sotto il motivo e altre disponibilità così il
          terapeuta potrà inviarti un nuovo appuntamento.
          <span style={{ fontWeight: 600 }}>
            {" "}
            Questo messaggio verrà inviato in chat al terapeuta.
          </span>
        </p>
      )
    }
  };

  const patientMoveoveAppointment = (
    <div className={styles.move}>
      {moveCancelAppointmentTitleText()}
      <div className={styles.textArea}>
        <Input.TextArea
          ref={inputRef}
          placeholder="Motivazione..."
          size="middle"
          autoSize={{ minRows: 7, maxRows: 7 }}
          value={message}
          onChange={(e) => {
            e.preventDefault();
            setValue(e.target.value);
          }}
        />
      </div>
      <div className={styles.btnWrapper}>
        <Button
          onClick={closeModal}
          className={styles.cancelBtn}
          variant="secondary"
        >
          Annulla
        </Button>
        <Button
          className={styles.confirmBtn}
          disabled={message.trim() === ""}
          onClick={() => {
            showMessage();
          }}
        >
          {userInfo?.user.role === "patient"
            ? therapyType === 'psychiatry' ? 'Richiedi allo psichiatra' : 'Richiedi al terapeuta'
            : "Sposta e invia al paziente"}
        </Button>
      </div>
    </div>
  );

  const therapistMoveAppointment = (
      <AppointmentCalendar
        insideForm
        chat={chat}
        closeModal={closeModal}
        patientId={patientId}
        appointmentToModify={getAppointment?.data?.appointment}
        onMove={onMove}
        firstAppointment={getAppointment?.data?.appointment.isFirstAppointment}
      />
  );

  return (
    <>
      <FormHeader
        title="Sposta o cancella appuntamento"
        closeModal={closeModal}
        closeButton
      />
      {getAppointment?.isLoading ? <Spin indicator={<LoadingOutlined style={{fontSize: 32}} spin/>}/> :
        <>
          {userInfo?.user.role === 'therapist' && (
            <div className={styles.therapistSubtitle}>
              <span>Avvisa sempre il paziente via chat</span> prima di spostare o
              cancellare un appuntamento.
            </div>
          )}
          <Tabs
            className={styles.tabs}
            items={[
              {
                key: 1,
                label: 'Sposta',
                active: activeSection === 'move',
                content:
                  userInfo?.user.role === 'patient'
                    ? patientMoveoveAppointment
                    : therapistMoveAppointment,
              },
              {
                key: 2,
                disabled: getAppointment?.data?.appointment.status === 'expired',
                label: getAppointment?.data?.appointment.status === 'payed_moved' ? 'Rimborso' : 'Cancella',
                active: activeSection === 'cancel',
                content: (
                  <div className={styles.move}>
                    {userInfo?.user.role === 'patient' ? (
                      moveCancelAppointmentTitleText()
                    ) : (
                      <p>
                        Scrivi in questo box la motivazione per la cancellazione
                        dell’appuntamento,
                        <span style={{fontWeight: 600}}>
                      {" "}
                          questo messaggio verrà inviato nella chat con il paziente.
                    </span>
                      </p>
                    )}
                    <div className={styles.textArea}>
                      <Input.TextArea
                        placeholder="Motivazione..."
                        size="middle"
                        autoSize={{minRows: 7, maxRows: 7}}
                        value={message}
                        onChange={(e) => {
                          e.preventDefault();
                          setActiveSection('cancel');
                          setValue(e.target.value);
                        }}
                      />
                    </div>
                    <div className={styles.btnWrapper}>
                      <Button
                        onClick={closeModal}
                        className={styles.cancelBtn}
                        variant="secondary"
                      >
                        Annulla
                      </Button>
                      <Button
                        className={styles.confirmBtn}
                        disabled={message.trim() === ''}
                        onClick={(e: any) => {
                          e.preventDefault();
                          onCancelAppointment();
                        }}
                      >
                        Cancella e invia al{' '}
                        {userInfo?.user.role === 'patient'
                          ? 'terapeuta'
                          : 'paziente'}
                      </Button>
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </>
      }
    </>
  );
};
