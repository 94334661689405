import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import IntroSurvey from '../../components/organisms/IntroSurvey'
import QuestionSurvey from '../../components/organisms/QuestionSurvey'
import styles from './Survey.module.scss'
import routes from 'routes'
import { useEffect, useState } from 'react'
import { analyticsService } from '../../utils/analyticsService';
import { trpc } from '../../utils/trpc';
import { getTrackingCampaignUtmData } from '../../utils/cookies';


export const Survey = ({className}: any) => {
  const navigate = useNavigate()
  const [pageNumberLocal, setPageNumberLocal] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)
  const [forceUpdate, setForceUpdate] = useState(false)
  const [dataSource, setDataSource] = useState('')
  const params = useParams()
  const location = useLocation()
  const {question} = params
  const [searchParams] = useSearchParams();
  let campaignId = ''

  const medium = searchParams.get("utm_medium");
  const source = searchParams.get("utm_source");
  const campaign = searchParams.get("utm_campaign")

  const sendTrackingCampaign =
    trpc.mainService.tracking.sendTrackingCampaign.useMutation();

  useEffect(() => {
    (async () => {
      const utmData: any = getTrackingCampaignUtmData('utm_data');
      if (medium && source && campaign) {
        await setTrackingCampaign(medium, source, campaign);
      } else if (utmData?.utm_medium && utmData?.utm_source && utmData?.utm_campaign) {
        await setTrackingCampaign(utmData?.utm_medium, utmData?.utm_source, utmData?.utm_campaign);
      }
    })();
  }, []);

  const setTrackingCampaign = async (medium: string, source: string, campaign: string) => {
    const storeCampaignId = localStorage.getItem('campaignId');
    setDataSource(source)
    if (!storeCampaignId) {
      const trackingCampaign: any = await sendTrackingCampaign.mutateAsync({
        medium,
        source,
        campaign,
      });
      localStorage.setItem('campaignId', trackingCampaign?.campaign?.id);
    } else {
      campaignId = storeCampaignId;
    }
  }


  const setPage = (val: number) => {
    setForceUpdate(prev => !prev)
    setPageNumber(0)
    setPageNumberLocal(val)
  }
    
  useEffect(() => {
    analyticsService({'event': 'virtualPageview', 'pageUrl': '/questionario/', 'pageTitle': 'Survey'});
  }, []);

  useEffect(() => {
    function closeQuickView() {
      if (pageNumberLocal === 0) {
        navigate(routes.Survey.links.intro)
      } else {
        setPageNumber(pageNumberLocal)
      }
    }

    window.addEventListener('popstate', closeQuickView)
    window.history.pushState('fake-route', document.title, window.location.href)
    return () => {
      window.removeEventListener('popstate', closeQuickView)
    }

  }, [pageNumberLocal, location, forceUpdate])


  return (
    <div className={`${styles.survey} ${className}`}>
      {question === 'introduzione' && (
        <IntroSurvey
          startSurvey={() => {
            navigate(routes.Survey.links.start, {state: {campaignId}})
          }}
        />
      )}
      {question === 'incorso' && <QuestionSurvey setPageNumber={setPage} pageNumber={pageNumber} source={dataSource}/>}
    </div>
  )
}
