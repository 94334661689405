const logoutCleanup = (keepLocalStorageKeys: string[] = []) => {
  Object.keys(localStorage).forEach((key) => {
    const shouldKeep =
      keepLocalStorageKeys.includes(key) || (keepLocalStorageKeys.includes('fakeSlots_') && key.startsWith('fakeSlots_'));

    if (!shouldKeep) {
      localStorage.removeItem(key);
    }
  });

  sessionStorage.clear();

  const keepCookies = ['pubtech-cmp-pcstring', 'euconsent-v2'];
  document.cookie.split(';').forEach((cookie) => {
    const cookieName = cookie.split('=')[0].trim();
    if (!keepCookies.includes(cookieName)) {
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    }
  });
}

export default logoutCleanup;